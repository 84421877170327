<template>
  <div class="add-new-empoyee">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.skillMatrixUpdate')"
      :title="$t('messages.skillMatrixUpdate')"
      @submitPressed="submitPressed"
    />
    <skillMatrix-form
      v-if="skillMatrix"
      :skillMatrixObject="skillMatrix"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import SkillMatrixForm from "./components/SkillMatrixForm.vue";

export default {
  components: {
    TitleBar,
    SkillMatrixForm,
  },
  data() {
    return {
      skillMatrix: null,
      action: null,
      skillMatrixList: null,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      const id = this.$route.params.id;
        this.$SkillMatrices.getResource({ id }, 'skill_matrix_edit').then((response) => {
            this.skillMatrix = response.data;
            if (this.skillMatrix.skill) {
              this.skillMatrix.skillSelect = {
                  label: this.skillMatrix.skill.type.name + ' - ' + this.skillMatrix.skill.skillNo + ' ' + this.skillMatrix.skill.skill,
                  id: this.skillMatrix.skill['@id']
              }
            }
            if (this.skillMatrix.employee) {
              this.skillMatrix.employeeSelect = {
                  label: (this.skillMatrix.employee.number ? this.skillMatrix.employee.number + ' ' : '') + this.skillMatrix.employee.givenName+' '+this.skillMatrix.employee.familyName,
                  id: this.skillMatrix.employee['@id']
              }
            }
        });
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
